@import '../base/variables';
@import '../base/colors';
@import '../base/typography';

.password-input {
  .input-wrapper {
    input.string-input {
      padding-right: 32px;
    }
  }
  .eye-icon-container {
    position: absolute;
    right: 7px;
    bottom: 12px;
    display: flex;
    align-items: center;

    &.is-invalid {
      right: 32px;
    }
  }
  .eye-icon {
    position: relative;
    cursor: pointer;
  }
  &.is-invalid {
    .input-wrapper {
      input.string-input {
        padding-right: 57px;
      }
    }
  }
}

// The following is needed to fix the issue with the eye icon not being displayed in the correct position in Safari
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .password-input {
      .input-wrapper {
        input.string-input {
          padding-right: 57px;
        }
      }
      .eye-icon-container {
        right: 32px;

        &.is-invalid {
          right: 57px;
        }
      }
      &.is-invalid {
        .input-wrapper {
          input.string-input {
            padding-right: 82px;
          }
        }
      }
    }
  }
}

// This is for hiding the browser's default password reveal button in Edge
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
